import { Especes, LoadingIcon } from "../../../assets/images";
import { BASE_IMAGE_URL } from "../../../constants/actions";
import formatDate2 from "../../../helpers/formatDate2";
import FormateDateRange from "../../../helpers/FormateDateRange";
import { UserIcon } from "@heroicons/react/24/solid"; // Import UserIcon from Heroicons
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { CoinsIcon, UserX, Wallet } from "lucide-react";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Clock } from "lucide-react";
import { apiClient } from "../../../actions/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/authContext";

const ReservationDetails = ({
  reservation,
  handlePayer,
  handleBlacklist,
  refresh,
  loading,
}) => {
  const PaymentMethodCell = (paymentMethod) => {
    const getIconAndText = () => {
      switch (paymentMethod) {
        case 'NAPS':
        return { 
          Icon: CreditCardIcon, 
          text: 'Par Carte' 
        };
        case 'naps':
          return { 
            Icon: CreditCardIcon, 
            text: 'Par Carte' 
          };
          
          case "CASH":
          return {
            Icon: CoinsIcon,
            text: "Espèces",
          };
        case "cash":
          return {
            Icon: CoinsIcon,
            text: "Espèces",
          };
        case "PAYPAL":
          return {
            Icon: (props) => (
              <svg {...props} viewBox="0 0 24 24" fill="currentColor">
                <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.025-2.596 4.692-6.001 4.692h-2.19a.563.563 0 0 0-.556.476l-1.187 7.517h4.282c.47 0 .876-.339.947-.806l.039-.23.74-4.693.047-.245c.072-.467.478-.806.948-.806h.597c3.863 0 6.889-1.57 7.772-6.11.37-1.904.18-3.501-.8-4.508z" />
              </svg>
            ),
            text: "PayPal",
          };
        case "paypal":
          return {
            Icon: (props) => (
              <svg {...props} viewBox="0 0 24 24" fill="currentColor">
                <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.025-2.596 4.692-6.001 4.692h-2.19a.563.563 0 0 0-.556.476l-1.187 7.517h4.282c.47 0 .876-.339.947-.806l.039-.23.74-4.693.047-.245c.072-.467.478-.806.948-.806h.597c3.863 0 6.889-1.57 7.772-6.11.37-1.904.18-3.501-.8-4.508z" />
              </svg>
            ),
            text: "PayPal",
          };
        case "GATEWAY":
          return {
            Icon: CreditCardIcon,
            text: "Gateway",
          };
        case "gateway":
          return {
            Icon: CreditCardIcon,
            text: "Gateway",
          };

        case "WALLET":
          return {
            Icon: Wallet,
            text: "Portfeuille",
          };
        case "wallet":
          return {
            Icon: Wallet,
            text: "Portfeuille",
          };
        default:
          return {
            Icon: null,
            text: paymentMethod,
          };
      }
    };

    const { Icon, text } = getIconAndText();

    return (
      <td className="text-sm text-left">
        <div className="flex items-center">
          {Icon && <Icon className="h-5 w-5 mr-2 text-orange-600" />}
          <span>{text}</span>
        </div>
      </td>
    );
  };
  const { isSuperAdmin } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading2, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("00:00");
  const [allServices, setAllServices] = useState([]);

  useEffect(() => {
    apiClient()
      .get(`/services`)
      .then((res) => {
        setAllServices(res?.data);
      });
  }, []);

  const now = new Date();
  const bookingDate = new Date(
    `${reservation?.availability?.date}T${reservation?.availability?.start_time}`
  );

  const handleSubmitService = async (e, paymentId) => {
    e.preventDefault();
    setLoading(true);

    const newService = {
      service_id: selectedService,
      price: parseFloat(price),
      duration: duration,
    };

    try {
      const response = await apiClient().post(
        `/bookings/${reservation?.id}/attach-service`,
        newService
      );

      if (response?.data?.status) {
        await apiClient().put(`/payments/${paymentId}`, {
          extra: Number(reservation?.payment?.extra) + Number(newService?.price),
        });

        setOpen(false);
        // Reset form
        setSelectedService("");
        setPrice("");
        setDuration("00:00");

        toast.success("Service ajouté avec succès !");
        refresh();
      } else {
        toast.error("Une erreur s'est produite, veuillez réessayer.");
      }
    } catch (error) {
      toast.error("Erreur lors de l'ajout du service !");
    } finally {
      setLoading(false);
    }
  };

  const handleDurationChange = (e) => {
    let value = e.target.value;
    // Only allow numbers and :
    value = value.replace(/[^\d:]/g, "");
    // Format as XX:XX
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    if (value.length === 2 && !value.includes(":")) {
      value += ":";
    }
    setDuration(value);
  };

  return (
    <div className="space-y-6">
      {/* Client Information */}
      <div className="p-6 bg-white shadow rounded-lg flex flex-col sm:flex-row gap-4 items-start sm:items-center">
        <div className="w-14 h-14 rounded-full bg-gray-200 overflow-hidden flex items-center justify-center">
          {reservation?.user?.avatar ? (
            <img
              src={BASE_IMAGE_URL + reservation?.user?.avatar}
              alt="avatar"
              className="object-cover w-full h-full"
            />
          ) : (
            <UserIcon className="mt-6 h-14 w-14 text-gray-400" /> // Fallback to UserIcon
          )}
        </div>
        <div className="flex flex-wrap w-full gap-6 sm:justify-between">
          <div className="space-y-2">
            <p className="text-gray-pt text-xs">Client</p>
            <h4 className="text-gray-gt font-bold text-sm sm:text-base capitalize">
              {reservation?.user?.first_name
                ? `${reservation?.user?.first_name} ${reservation?.user?.last_name}`
                : reservation?.guest?.name}
            </h4>
          </div>
          <div className="space-y-2">
            <p className="text-gray-pt text-xs">Salon</p>
            <h4 className="text-gray-gt font-bold text-sm sm:text-base capitalize">
              {reservation?.salon?.name || "N/A"}
            </h4>
          </div>
          <div className="space-y-2">
            <p className="text-gray-pt text-xs">Email</p>
            <h4 className="text-gray-gt font-bold text-sm sm:text-base">
              {reservation?.user?.email || reservation?.guest?.email || "N/A"}
            </h4>
          </div>
          <div className="space-y-2">
            <p className="text-gray-pt text-xs">Téléphone</p>
            <h4 className="text-gray-gt font-bold text-sm sm:text-base">
              {reservation?.user?.phone || reservation?.guest?.phone || "N/A"}
            </h4>
          </div>
        </div>
      </div>

      {/* Payment & Appointment Details */}
      <div className="p-6 bg-white shadow rounded-lg grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <p className="text-gray-pt text-xs">Date de rendez-vous</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base">
            {reservation?.availability?.date &&
              FormateDateRange(
                reservation?.availability?.date,
                reservation?.availability?.start_time,
                reservation?.availability?.end_time
              )}
          </h4>
        </div>
        <div>
          <p className="text-gray-pt text-xs">Réservé le</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base">
            {reservation?.created_at && formatDate2(reservation?.created_at)}
          </h4>
        </div>
        <div>
          <p className="text-gray-pt text-xs">Prix Total</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base">
            {Number(reservation?.payment?.extra) !== 0 ? (
              <span>
                {Number(reservation?.payment?.amount) +
                  Number(reservation?.payment?.extra)}{" "}
                Dhs{" "}
                <span className="text-gray-500 text-sm">(avec les suppléments)</span>
              </span>
            ) : (
              <span>{reservation?.payment?.amount} Dhs</span>
            )}
          </h4>
        </div>
        <div>
          <p className="text-gray-pt text-xs">Mode Paiement</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base uppercase">
            {PaymentMethodCell(reservation?.payment?.payment_method)}
          </h4>
        </div>
      </div>

      {/* Pay Button for Cash Payments */}
      {!isSuperAdmin && reservation?.payment?.payment_method.toLowerCase() === "cash" && reservation?.payment?.payment_status !== 2 && (
        <div className="flex flex-col sm:flex-row justify-between gap-4 px-4">
          {(now > bookingDate ||
            reservation?.payment?.payment_status === 2) && (
            <div className="w-full sm:w-auto">
              <button
                onClick={() =>
                  handleBlacklist(
                    reservation?.user || reservation?.guest,
                    reservation?.payment?.id
                  )
                }
                disabled={loading}
                className={`w-full sm:w-auto group flex items-center justify-center sm:justify-start space-x-2 px-4 py-2 ${
                  loading
                    ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                    : "bg-white text-red-600 hover:bg-red-50"
                } border border-red-300 rounded-full font-semibold shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50`}
              >
                {loading ? (
                  <img src={LoadingIcon} alt="loading" className="h-6" />
                ) : (
                  <div className="flex items-center gap-2 sm:gap-4">
                    <UserX className="h-6 shrink-0" />
                    <span className="text-sm sm:text-base">
                      Client absent au rendez-vous
                    </span>
                  </div>
                )}
              </button>
            </div>
          )}
          {reservation?.payment?.payment_status === 0 && (
            <div className="w-full sm:w-auto">
              <button
                onClick={() => handlePayer(reservation?.payment?.id)}
                disabled={loading}
                className={`w-full sm:w-auto group flex items-center justify-center sm:justify-start space-x-2 px-4 py-2 ${
                  loading
                    ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                    : "bg-white text-green-600 hover:bg-green-50"
                } border border-green-300 rounded-full font-semibold shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50`}
              >
                {loading ? (
                  <img src={LoadingIcon} alt="loading" className="h-6" />
                ) : (
                  <div className="flex items-center gap-2 sm:gap-4">
                    <img src={Especes} alt="especes" className="h-6 shrink-0" />
                    <span className="text-sm sm:text-base">
                      Confirmer le paiement en espèces
                    </span>
                  </div>
                )}
              </button>
            </div>
          )}
        </div>
      )}

      {/* Services */}
      <div className="p-6 bg-white shadow rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold text-gray-gt">Services</h3>
          {!isSuperAdmin && <button
            onClick={() => setOpen(true)}
            className="bg-orange-600 hover:bg-orange-700 text-white px-4 py-2 rounded-lg flex items-center gap-2 transition-colors duration-200"
          >
            <span className="text-sm font-medium">
              Ajouter d'autres services
            </span>
          </button>}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
          {reservation?.services?.map((service, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300 overflow-hidden"
            >
              <div className="bg-lightorange text-white text-center py-3 font-semibold text-lg">
                {service?.name}
              </div>
              <div className="text-center py-3 border-t border-gray-300">
                <span className="text-gray-700 font-medium">
                  {service?.pivot?.price} Dhs
                </span>
              </div>
            </div>
          ))}
        </div>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <form
            onSubmit={(e) => handleSubmitService(e, reservation?.payment?.id)}
          >
            <DialogTitle className="text-xl font-bold">
              Ajouter un autre service
            </DialogTitle>
            <DialogContent className="mt-4">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Service
                  </label>
                  <select
                    value={selectedService}
                    onChange={(e) => setSelectedService(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                    required
                  >
                    <option value="">Sélectionner un service</option>
                    {allServices?.map((service) => {
                      const isDisabled = reservation?.services?.some(
                        (resService) => resService.id === service.id
                      );

                      return (
                        <option
                          key={service.id}
                          value={service.id}
                          disabled={isDisabled}
                        >
                          {service.name} {isDisabled ? "(Déjà ajouté)" : ""}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Prix (Dhs)
                  </label>
                  <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                    placeholder="0"
                    min="0"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Durée
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={duration}
                      onChange={handleDurationChange}
                      placeholder="00:00"
                      className="w-full px-3 py-2 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                      pattern="[0-9]{2}:[0-9]{2}"
                      required
                    />
                    <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="p-4">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
              >
                Annuler
              </button>
              <button
                type="submit"
                className={`px-4 py-2 text-white rounded-lg transition-colors duration-200 ${
                  loading2
                    ? "bg-orange-400 cursor-not-allowed"
                    : "bg-orange-600 hover:bg-orange-700"
                }`}
                disabled={loading2}
              >
                {loading2 ? (
                  <div className="flex items-center gap-2">
                    <span className="animate-spin h-5 w-5 border-t-2 border-white rounded-full"></span>
                    Ajout en cours...
                  </div>
                ) : (
                  "Ajouter"
                )}
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </div>

      {/* Notes Section */}
      {reservation?.note && (
        <div className="p-6 bg-white shadow rounded-lg">
          <h3 className="text-lg font-bold text-gray-gt">Notes</h3>
          <p className="mt-4 text-gray-700">{reservation?.note}</p>
        </div>
      )}

      {/* Employee Information */}
      <div className="p-6 bg-white shadow rounded-lg flex items-center gap-4">
        <div className="w-14 h-14 rounded-full bg-gray-200 overflow-hidden flex items-center justify-center">
          {reservation?.employee?.avatar ? (
            <img
              src={BASE_IMAGE_URL + reservation?.employee?.avatar}
              alt="avatar"
              className="object-cover w-full h-full"
            />
          ) : (
            <UserIcon className="mt-6 h-14 w-14 text-gray-400" /> // Fallback to UserIcon
          )}
        </div>
        <div className="space-y-2">
          <p className="text-gray-pt text-xs">Employé</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base capitalize">
            {reservation?.availability?.employee?.first_name}{" "}
            {reservation?.availability?.employee?.last_name}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ReservationDetails;
