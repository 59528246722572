import React, { useEffect, useState } from "react";
import { ComeBackButton, ParametresLayout } from "../../components";
import { useLocation, useNavigate } from "react-router";
import { apiClient } from "../../actions/api";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FetchData } from "../../actions/data";
import { SERVICES } from "../../constants/actions";
import { useAuth } from "../../context/authContext";

function AddServiceSalon() {
  const [allServices, setAllServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [formData, setFormData] = useState([]);
  const [newService, setNewService] = useState({
    name: "",
    categorie: "",
    icon: "",
  });
  const { isSuperAdmin } = useAuth();
  const location = useLocation();
  const services = useSelector((state) => state?.data?.services);
  const salon = useSelector((state) => state?.data?.salon);
  const user = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    apiClient()
      .get(`/services`)
      .then((res) => {
        setAllServices(res?.data);
      });
  }, []);

  const handleServiceSelect = (e) => {
    const selectedServiceId = e.target.value;
    const isServiceAlreadySelected = services?.some(
      (service) => service.id === parseInt(selectedServiceId)
    );

    if (isServiceAlreadySelected) {
      toast.error("Ce service est déjà sélectionné.");
      return;
    }

    const selected = allServices.find((s) => s.id === parseInt(selectedServiceId));
    if (selected) {
      setSelectedService(selected);
      setFormData({
        service: selected.id,
        price: selected.price || "",
        duration: selected.duration || "",
        description: selected.description || "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTimeChange = (value) => {
    setFormData((prev) => ({ ...prev, duration: value }));
  };

  const handleSave = () => {
    const duration = formData?.duration
      ? dayjs(formData?.duration, "HHmm").isValid()
        ? dayjs(formData?.duration, "HHmm").format("HH:mm")
        : "00:30"
      : "00:30";

    let datapi = {
      salon_id: salon?.id,
      pivot: {
        price: formData?.price,
        duration,
        description: formData?.description,
      },
    };

    apiClient()
      .post(`/services/${formData?.service}/create-pivot`, datapi)
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Services enregistrés avec succès !");
          dispatch(FetchData(`/salonServices/${user?.id}`, SERVICES));
          navigate(location?.state?.prevPath);
        }
      });
  };

  const handleNewServiceChange = (e) => {
    const { name, value } = e.target;
    setNewService((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewServiceSubmit = () => {
    if (!newService.name || !newService.categorie || !newService.icon) {
      toast.error("Tous les champs sont requis !");
      return;
    }

    apiClient()
      .post("/services", newService)
      .then((res) => {
        if (res.data?.id) {
          toast.success("Service ajouté avec succès !");
          setNewService({ name: "", categorie: "", icon: "" });
          dispatch(FetchData(`/services`, SERVICES));
        }
      });
  };

  return (
    <ParametresLayout>
      <div className="px-1 py-5 min-h-screen">
        <ComeBackButton prev={location?.state?.prevPath} next={"/parametres/addservices"} />
        <div className="services-section">
          {/* Sélection des services pour l'admin */}
          {!isSuperAdmin && <div className="mb-4">
            <select
              className="w-md p-2 border border-orange-400 rounded-lg outline-none focus:outline-none"
              onChange={handleServiceSelect}
              defaultValue=""
            >
              <option value="" disabled>
                Sélectionnez un service
              </option>
              {allServices?.map((service) => (
                <option
                  key={service.id}
                  value={service.id}
                  className={`${services?.some((s) => s.id === service.id) && "bg-gray-600 text-white"} text-sm`}
                >
                  {service.name}
                </option>
              ))}
            </select>
          </div>}

          {/* Affichage des détails du service sélectionné */}
          {selectedService && (
            <div className="mt-6">
              <div className="flex flex-col gap-2">
                <h3 className="font-bold mx-auto text-lg text-orange-500">
                  {selectedService.name}
                </h3>
                <div className="flex flex-col gap-2">
                  <label htmlFor="price">Prix</label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md outline-none focus:outline-none"
                    step="0.01"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="duration">Durée</label>
                  <TimePicker
                    value={formData.duration ? dayjs(formData.duration, "HH:mm") : null}
                    onChange={handleTimeChange}
                    format="HH:mm"
                    className="w-full p-2 border rounded-md outline-none focus:outline-none font-semibold"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="description">Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="w-full p-2 min-h-24 border rounded-md outline-none focus:outline-none"
                  ></textarea>
                </div>
                <button
                  className="bg-orange-500 text-white px-4 py-2 rounded-md mt-4"
                  onClick={handleSave}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          )}

          {/* Formulaire pour super admin */}
          {isSuperAdmin && (
            <div className="mt-8 p-4 max-w-2xl mx-auto bg-white rounded-lg shadow-md">
              <h2 className="text-lg font-bold text-orange-600">Ajouter un nouveau service</h2>
              <div className="flex flex-col gap-2 mt-4">
                <input
                  type="text"
                  name="name"
                  placeholder="Nom du service"
                  value={newService.name}
                  onChange={handleNewServiceChange}
                  className="p-2 border rounded-md"
                />
                <input
                  type="text"
                  name="categorie"
                  placeholder="Catégorie"
                  value={newService.categorie}
                  onChange={handleNewServiceChange}
                  className="p-2 border rounded-md"
                />
                <input
                  type="text"
                  name="icon"
                  placeholder="Icône"
                  value={newService.icon}
                  onChange={handleNewServiceChange}
                  className="p-2 border rounded-md"
                />
                <button
                  className="bg-orange-500 text-white px-4 py-2 rounded-md mt-2"
                  onClick={handleNewServiceSubmit}
                >
                  Ajouter
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </ParametresLayout>
  );
}

export default AddServiceSalon;