import React, { useEffect, useState } from "react";
import { ComeBackButton, Layout, ReservationDetails } from "../../components";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../context/authContext";
import { FetchData } from "../../actions/data";
import { BOOKINGS } from "../../constants/actions";
import { apiClient } from "../../actions/api";

const ReservationDeatails = () => {
  const { id } = useParams();
  const reservations = useSelector((state) => state?.data?.bookings);
  const [reservation, setReservation] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isSuperAdmin } = useAuth();
  const user = useSelector((state) => state?.auth?.user);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (isNaN(id)) {
      navigate(location?.state?.prevPath || "/reservations");
      return;
    }
    const res = reservations?.find((res) => res?.id === parseInt(id));
    if (reservations?.length > 0 && !res) {
      navigate(location?.state?.prevPath || "/reservations");
      return;
    }
    setReservation(res);
  }, [id, reservations]);

  const handleBlacklist = async (userclient, paymentId) => {
    const cofirme = window.confirm(
      "Êtes-vous sûr de vouloir procéder ?"
    );
    if (cofirme) {
      setLoading(true)
      try {
        await apiClient().post('/blacklist', {
          email: userclient?.email,
          reason: "Client absent au rendez-vous",
          user_id: userclient?.id,
        });
        await apiClient().put(`/payments/${paymentId}`, {
          payment_status: 2,
        });
        toast.success("Le client a été ajouté à la liste noire !");
        isSuperAdmin
        ? dispatch(FetchData("/bookings", BOOKINGS))
        : dispatch(FetchData(`/salonBookings/${user?.id}`, BOOKINGS));
        setLoading(false)
      } catch (err) {
        console.error(err);
        toast.warning("L'ajout a échoué.");
        setLoading(false)
      }
    }
  }

  const handlePayer = async (paymentId) => {
    const cofirme = window.confirm(
      "Êtes-vous sûr de vouloir procéder au paiement en espèces ?"
    );
    if (cofirme) {
      setLoading(true)
      try {
        await apiClient().put(`/payments/${paymentId}`, {
          payment_status: true,
        });
        toast.success("Le paiement en espèces a été effectué avec succès.");
        isSuperAdmin
        ? dispatch(FetchData("/bookings", BOOKINGS))
        : dispatch(FetchData(`/salonBookings/${user?.id}`, BOOKINGS));
        setLoading(false)
      } catch (err) {
        console.error(err);
        toast.warning("Le paiement en espèces a échouée.");
        setLoading(false)
      }
      // dispatch(payerEnEspece(paymentId))
      //   .then((response) => {
      //     toast.success("Le paiement en espèces a été effectué avec succès.");
      //     isSuperAdmin
      //       ? dispatch(FetchData("/bookings", BOOKINGS))
      //       : dispatch(FetchData(`/salonBookings/${user?.id}`, BOOKINGS));
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     toast.warning("Le paiement en espèces a échouée.");
      //   });
    }
  };


  const refresh = () => {
        isSuperAdmin
        ? dispatch(FetchData("/bookings", BOOKINGS))
        : dispatch(FetchData(`/salonBookings/${user?.id}`, BOOKINGS));
  };

  return (
    <Layout>
      <div className="">
        <ComeBackButton prev={location?.state?.prevPath} next={"/reservations"} />
        <ReservationDetails
          refresh={refresh}
          reservation={reservation}
          handlePayer={handlePayer}
          handleBlacklist={handleBlacklist}
          loading={loading}
        />
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default ReservationDeatails;
