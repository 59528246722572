import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import {
  Calendrier,
  ClientDeatails,
  Clients,
  Dashboard,
  Notifications,
  Parametres,
  ReservationDeatails,
  Reservations,
  Login,
  Profile,
  PaiementDetails,
  Salons,
  Employes,
  SalonDetails,
  EmployesDetails,
  Avis,
  Emails,
  Remarques,
  Users,
  Propos,
  Blog,
  BlogDetails,
  UserDetails,
  Complaints,
  Salon,
  Services,
  ServiceDetails,
  MessagerieDetails,
  AjouterBlog,
  AddEmployee,
  Demmandes,
  DemandesDetails,
  CmsLayout,
  Conditions,
  RgbdPage,
  Entreprise,
  Accueil,
  FooterPage,
  AddServiceSalon,
  PromoCodes,
  Comission,
} from "../pages";
import { NotFound } from "../components";
import { AuthProvider, useAuth } from "../context/authContext";
import Paiements from "../pages/paiements";
import Messagerie from "../pages/messagerie";
import { useDispatch, useSelector } from "react-redux";
import { FetchData } from "../actions/data";
import {
  ACCUEIL,
  AVAILABILITIES,
  BLOGS,
  BOOKINGS,
  CALENDARS,
  CHATS,
  CLIENTS,
  COMPLAINTS,
  COMPLETEDSALONS,
  CONDITIONS,
  DEMANDES,
  EMAILS,
  EMPLOYEES,
  ENTREPRISE,
  FAQS,
  FEEDBACKS,
  FOOTER,
  GALERIE,
  GUESTS,
  NOTES,
  PAYMENTS,
  POLITIQUES,
  PROPOS,
  REASONS,
  REVIEWS,
  RGBD,
  SALON,
  SALONS,
  SERVICES,
  SUGGESTIONS,
  USERS,
} from "../constants/actions";
import Politiques from "../pages/politiques";

const PrivateRoute = () => {
  const { isUserAuthenticated } = useAuth();
  return isUserAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

const AdminRoute = () => {
  const { isSuperAdmin } = useAuth();
  return !isSuperAdmin ? <Outlet /> : <Navigate to="/" replace />;
};

const SuperAdminRoute = () => {
  const { isSuperAdmin } = useAuth();
  return isSuperAdmin ? <Outlet /> : <Navigate to="/" replace />;
};

const RedirectUserIfAuthenticated = ({ children }) => {
  const { isUserAuthenticated } = useAuth();
  return isUserAuthenticated ? <Navigate to="/" replace /> : children;
};

const Navigation = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user?.role === "admin") {
          const uris = [
            { uri: `/clients/${user?.id}`, action: CLIENTS },
            { uri: `/salonById/${user?.id}`, action: SALON },
            { uri: `/salonGuests/${user?.id}`, action: GUESTS },
            { uri: `/salonServices/${user?.id}`, action: SERVICES },
            { uri: `/salonAvailabilities/${user?.id}`, action: AVAILABILITIES },
            { uri: `/salonBookings/${user?.id}`, action: BOOKINGS },
            { uri: `/salonPayments/${user?.id}`, action: PAYMENTS },
            { uri: `/reviewsById/${user?.id}`, action: REVIEWS },
            { uri: `/complaintsById/${user?.id}`, action: COMPLAINTS },
            { uri: `/chatsById/${user?.id}`, action: CHATS },
            // `/calendars`,
            { uri: `/salonNotes/${user?.id}`, action: NOTES },
            { uri: `/salonEmployees/${user?.id}`, action: EMPLOYEES },
          ];
          await Promise.all(
            uris?.map((uri) => dispatch(FetchData(uri?.uri, uri?.action)))
          );
        }
        if (user?.role === "super_admin") {
          const uris = [
            { uri: "/users", action: USERS },
            { uri: "/guests", action: GUESTS },
            { uri: "/allClients", action: CLIENTS },
            { uri: "/salons", action: SALONS },
            { uri: "/completedSalons", action: COMPLETEDSALONS },
            { uri: "/services", action: SERVICES },
            { uri: "/availabilities", action: AVAILABILITIES },
            { uri: "/bookings", action: BOOKINGS },
            { uri: "/payments", action: PAYMENTS },
            { uri: `/reviewsById/${user?.id}`, action: REVIEWS },
            { uri: `/complaintsById/${user?.id}`, action: COMPLAINTS },
            { uri: `/suggestions`, action: SUGGESTIONS },
            { uri: `/chatsById/${user?.id}`, action: CHATS },
            { uri: "/calendars", action: CALENDARS },
            { uri: "/disabledUsers", action: DEMANDES },
            { uri: "/notes", action: NOTES },
            { uri: "/employees", action: EMPLOYEES },
            { uri: "/blogs", action: BLOGS },
            { uri: "/emails", action: EMAILS },
            { uri: "/propos", action: PROPOS },
            { uri: "/accueil", action: ACCUEIL },
            { uri: "/reasons", action: REASONS },
            { uri: "/feedbacks", action: FEEDBACKS },
            { uri: "/questions", action: FAQS },
            { uri: "/entreprise", action: ENTREPRISE },
            { uri: "/galerie", action: GALERIE },
            { uri: "/footer", action: FOOTER },
            { uri: "/conditions", action: CONDITIONS },
            { uri: "/politiques", action: POLITIQUES },
            { uri: "/rgbd", action: RGBD },
          ];
          await Promise.all(
            uris?.map((uri) => dispatch(FetchData(uri?.uri, uri?.action)))
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [dispatch, user]);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <RedirectUserIfAuthenticated>
                <Login />
              </RedirectUserIfAuthenticated>
            }
          />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/reservations" element={<Reservations />} />
            <Route path="/reservations/:id" element={<ReservationDeatails />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/:id" element={<ClientDeatails />} />
            <Route path="/paiements" element={<Paiements />} />
            <Route path="/paiements/:id" element={<PaiementDetails />} />
            <Route path="/messagerie" element={<Messagerie />} />
            <Route path="/messagerie/:id" element={<MessagerieDetails />} />
            <Route path="/calendrier" element={<Calendrier />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route
              path="/parametres"
              element={<Navigate to="/parametres/avis" replace />}
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/parametres/avis" element={<Avis />} />
            <Route path="/parametres/remarques" element={<Remarques />} />
            <Route path="/parametres/services" element={<Services />} />
            <Route path="/parametres/addservices" element={<AddServiceSalon />} />
            <Route
              path="/parametres/services/:id"
              element={<ServiceDetails />}
            />

            <Route path="/parametres/comission" element={<Comission />} />

            {/* admin routes */}
            <Route element={<AdminRoute />}>
              <Route path="/parametres/employees" element={<Employes />} />
              <Route
                path="/parametres/employees/:id"
                element={<EmployesDetails />}
              />
              <Route path="/parametres/addEmployee" element={<AddEmployee />} />
              <Route path="/parametres/salon" element={<Salon />} />
            </Route>

            {/* suepr_admin routes */}
            <Route element={<SuperAdminRoute />}>
              <Route path="/parametres/plaintes" element={<Complaints />} />
              <Route path="/parametres/emails" element={<Emails />} />
              <Route path="/parametres/salons" element={<Salons />} />
              <Route path="/parametres/promo-codes" element={<PromoCodes />} />
              <Route path="/parametres/salons/:id" element={<SalonDetails />} />
              <Route path="/demandes" element={<Demmandes />} />
              <Route path="/demandes/:id" element={<DemandesDetails />} />
              <Route path="/parametres/utilisateurs" element={<Users />} />
              <Route
                path="/parametres/utilisateurs/:id"
                element={<UserDetails />}
              />
              <Route
                path="/pages"
                element={<Navigate to="/pages/blogs" replace />}
              />
              <Route path="/pages/accueil" element={<Accueil />} />
              <Route path="/pages/propos" element={<Propos />} />
              <Route path="/pages/blogs" element={<Blog />} />
              <Route path="/pages/blogs/:id" element={<BlogDetails />} />
              <Route path="/pages/addBlog" element={<AjouterBlog />} />
              <Route path="/pages/conditions" element={<Conditions />} />
              <Route path="/pages/politiques" element={<Politiques />} />
              <Route path="/pages/rgbd" element={<RgbdPage />} />
              <Route path="/pages/entreprise" element={<Entreprise />} />
              <Route path="/pages/footer" element={<FooterPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Navigation;
