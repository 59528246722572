import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import SortBy from "../globalcomponents/SortByBtn";
import { ProfileImg } from "../../assets/images";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { EyeIcon } from '@heroicons/react/24/outline';

const ClientsTable = ({
  clients,
  itemsPerPage,
  searchInput,
  setSearchInput,
  sortOptions,
  sortOption,
  setSortOption,
  currentPage,
  setCurrentPage,
}) => {
  const [visiblePages, setVisiblePages] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const totalPages = Math.ceil(clients.length / itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    updateVisiblePages(currentPage);
  }, [currentPage, clients.length]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleRows = clients.slice(startIndex, startIndex + itemsPerPage);

  const renderPageButtons = () =>
    visiblePages.map((page) => (
      <button
        key={page}
        onClick={() => handlePageChange(page)}
        className={`mx-1 w-8 h-8 ${
          currentPage === page
            ? "bg-orange-500 text-white"
            : "text-gray-700 bg-gray-200"
        } rounded-full flex items-center justify-center`}
      >
        {page}
      </button>
    ));

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <div className="w-full md:w-1/3">
          <input
            type="text"
            name="nom"
            id="nom"
            placeholder="Nom, Prénom, Email"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
          />
        </div>
        <div className="relative">
          <SortBy
            sortOptions={sortOptions}
            sortOption={sortOption}
            setSortOption={setSortOption}
          />
        </div>
      </div>

      <div className="pt-6 overflow-x-auto">
        <table className="w-full text-sm">
          <thead>
            <tr className="bg-stone-500">
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">
                Client
              </th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">
                Email
              </th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">
                Téléphone
              </th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">
                Genre
              </th>
              <th className="py-3 px-4 text-white text-center whitespace-nowrap">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {clients.length ? (
              visibleRows.map((client) => (
                <tr
                  key={client?.id}
                  className="text-gray-gt border-b text-[10px] sm:text-xs md:text-sm lg:text-base"
                >
                  <td className="py-3 px-4 whitespace-nowrap">
                    <div className="flex items-center space-x-2 max-w-[200px]">
                      <img
                        src={
                          client?.avatar
                            ? BASE_IMAGE_URL + client?.avatar
                            : ProfileImg
                        }
                        alt="avatar"
                        className="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex-shrink-0"
                      />
                      <div className="flex-grow min-w-0">
                        <p className="text-sm text-semibold truncate">
                          {client?.first_name
                            ? `${client?.first_name} ${client?.last_name}`
                            : client?.name}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 whitespace-nowrap text-sm">
                    {client?.email || "N/A"}
                  </td>
                  <td className="py-3 px-2 whitespace-nowrap text-sm">
                    {client?.phone || "N/A"}
                  </td>
                  <td className="py-3 px-2 whitespace-nowrap capitalize text-sm">
                    {client?.gender ? client?.gender : "Non spécifié"}
                  </td>
                  {client?.first_name && (
                    <td className="py-2 text-center">
                      <button
                        onClick={() =>
                          navigate(`/clients/${client?.id}`, {
                            state: { prevPath: location?.pathname },
                          })
                        }
                        className="group p-1 rounded-full transition-all duration-300 ease-in-out hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-orange-500"
                        aria-label="Voir les détails"
                      >
                        <EyeIcon className="w-5 h-5 text-orange-400 group-hover:text-orange-600 transition-all duration-300 ease-in-out transform group-hover:scale-110" />
                      </button>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  Aucune réservation trouvée.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {clients.length > itemsPerPage && (
        <div className="flex justify-center items-center p-4 pb-12">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="mr-2 p-2 rounded-full bg-gray-200 disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>
          {renderPageButtons()}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="ml-2 p-2 rounded-full bg-gray-200 disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ClientsTable;
