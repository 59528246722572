import React, { useEffect, useState, useCallback } from "react";
import FilterBtn from "../../components/globalcomponents/FilterBtn";
import SortByBtn from "../../components/globalcomponents/SortByBtn";
import { useNavigate } from "react-router";
import { ParametresLayout } from "../../components";
import { Filter, Loader, Plus, Trash2, X } from "lucide-react";
import { apiClient } from "../../actions/api";
import { Box, Modal, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const sortOptions = ["Date création", "Code", "Réduction"];
const filterOptions = ["Tout", "Actif", "Expiré"];
const itemsPerPage = 10;

const GiftCodes = () => {
  const [giftCodes, setGiftCodes] = useState([]);
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date création");
  const [filterOption, setFilterOption] = useState("Tout");
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const [isFilterSliderOpen, setIsFilterSliderOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const [formData, setFormData] = useState({
    code: "",
    discount: "",
    expires_at: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchGiftCodes = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient().get("/gift-codes");
      setGiftCodes(response?.data);
    } catch (error) {
      console.error("Error fetching gift codes:", error);
      toast.error("Impossible de récupérer les codes cadeaux");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGiftCodes();
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedCode(null);
    setFormData({ code: "", discount: "", expires_at: "" });
  };

  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce code ?")) {
      setIsDeleting(true);
      try {
        await apiClient().delete(`/gift-codes/${id}`);
        toast.success("Code cadeau supprimé avec succès");
        fetchGiftCodes();
      } catch (error) {
        console.error("Error deleting gift code:", error);
        toast.error("Impossible de supprimer le code cadeau");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (selectedCode) {
        await apiClient().put(`/gift-codes/${selectedCode.id}`, formData);
        toast.success("Code cadeau modifié avec succès");
      } else {
        await apiClient().post("/gift-codes", formData);
        toast.success("Code cadeau créé avec succès");
      }
      handleModalClose();
      fetchGiftCodes();
    } catch (error) {
      console.error("Error saving gift code:", error);
      toast.error("Impossible de sauvegarder le code cadeau");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (code) => {
    setSelectedCode(code);
    setFormData({
      code: code.code,
      discount: code.discount,
      expires_at: code.expires_at.split("T")[0],
    });
    setModalOpen(true);
  };

  const handleAdd = () => {
    setSelectedCode(null);
    setFormData({ code: "", discount: "", expires_at: "" });
    setModalOpen(true);
  };

  useEffect(() => {
    fetchGiftCodes();
  }, []);

  const sortCodes = useCallback((codesToSort, option) => {
    return [...codesToSort].sort((a, b) => {
      if (option === "Date création") {
        return new Date(b?.created_at) - new Date(a?.created_at);
      } else if (option === "Code") {
        return a?.code.localeCompare(b?.code);
      } else if (option === "Réduction") {
        return b?.discount - a?.discount;
      }
      return 0;
    });
  }, []);

  const filterCodes = useCallback((codesToFilter, search, filter) => {
    let result = codesToFilter;

    if (search) {
      const searchLowerCase = search.trim().toLowerCase();
      result = result.filter((code) => {
        return code?.code?.toLowerCase().includes(searchLowerCase);
      });
    }

    if (filter === "Actif") {
      result = result.filter(
        (code) => new Date(code?.expires_at) > new Date() && !code?.used
      );
    } else if (filter === "Expiré") {
      result = result.filter(
        (code) => new Date(code?.expires_at) <= new Date() || code?.used
      );
    }

    return result;
  }, []);

  const updateCodesList = useCallback(() => {
    let result = giftCodes;
    result = filterCodes(result, searchInput, filterOption);
    result = sortCodes(result, sortOption);
    setFilteredCodes(result);
  }, [
    giftCodes,
    searchInput,
    filterOption,
    sortOption,
    filterCodes,
    sortCodes,
  ]);

  useEffect(() => {
    updateCodesList();
  }, [updateCodesList]);

  const totalPages = Math.ceil(filteredCodes.length / itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = useCallback(
    (page) => {
      let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
      let end = Math.min(totalPages, start + maxVisibleButtons - 1);

      if (end - start < maxVisibleButtons - 1) {
        start = Math.max(1, end - maxVisibleButtons + 1);
      }

      setVisiblePages(
        Array.from({ length: end - start + 1 }, (_, i) => start + i)
      );
    },
    [totalPages]
  );

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [filteredCodes, updateVisiblePages]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleFilterChange = (newFilterOption) => {
    setFilterOption(newFilterOption);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentCodes = filteredCodes.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const toggleFilterSlider = () => {
    setIsFilterSliderOpen((prev) => !prev);
  };

  return (
    <ParametresLayout>
    <ToastContainer />
      <div className="p-3 min-h-screen space-y-5">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="w-full md:w-1/3">
            <input
              type="text"
              name="code"
              id="code"
              placeholder="Rechercher un code cadeau"
              value={searchInput}
              onChange={handleSearchChange}
              className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
            />
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={handleAdd}
              className="bg-orange-500 text-white py-2 px-4 rounded-full flex items-center gap-2 hover:bg-orange-600 transition-colors"
            >
              <Plus size={18} /> Ajouter
            </button>
            <div className="md:hidden">
              <button
                onClick={toggleFilterSlider}
                className="bg-orange-500 text-white py-2 px-4 rounded-full flex items-center justify-center"
              >
                <Filter size={18} className="mr-2" /> Filtres
              </button>
            </div>
          </div>
          <div
            className={`flex flex-col md:flex-row gap-2 ${
              isFilterSliderOpen ? "block" : "hidden md:flex"
            }`}
          >
            <div className="relative">
              <SortByBtn
                sortOptions={sortOptions}
                sortOption={sortOption}
                setSortOption={handleSortChange}
              />
            </div>
            <div className="relative">
              <FilterBtn
                filterOptions={filterOptions}
                filterOption={filterOption}
                setFilterOption={handleFilterChange}
              />
            </div>
          </div>
        </div>

        <div className="bg-white text-sm shadow-md rounded-lg">
          <div className="overflow-x-auto">
          {isLoading ? (
              <div className="flex items-center justify-center p-8">
                <Loader className="w-6 h-6 animate-spin text-orange-500" />
                <span className="ml-2">Chargement des codes...</span>
              </div>
            ) : (<table className="w-full table-auto border-collapse">
              <thead>
                <tr className="bg-gray-100 text-gray-800 text-left border-b text-xs sm:text-sm md:text-sm lg:text-md font-semibold">
                  <th className="py-3 px-4">Code</th>
                  <th className="py-3 px-4">Réduction</th>
                  <th className="py-3 px-4">Date d'expiration</th>
                  <th className="py-3 px-4">Statut</th>
                  <th className="py-3 px-4 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentCodes.length ? (
                  currentCodes.map((code) => (
                    <tr
                      key={code?.id}
                      className="hover:bg-gray-50 text-gray-700 border-b text-xs sm:text-sm md:text-sm lg:text-md"
                    >
                      <td className="py-3 px-4 uppercase">{code?.code}</td>
                      <td className="py-3 px-4">{code?.discount}%</td>
                      <td className="py-3 px-4">
                        {new Date(code?.expires_at).toLocaleDateString()}
                      </td>
                      <td className="py-3 px-4">
                        <span
                          className={`px-2 py-1 rounded-full text-xs ${
                            new Date(code?.expires_at) > new Date() &&
                            !code?.used
                              ? "bg-green-100 text-green-800"
                              : "bg-red-100 text-red-800"
                          }`}
                        >
                          {new Date(code?.expires_at) > new Date() &&
                          !code?.used
                            ? "Actif"
                            : "Expiré"}
                        </span>
                      </td>
                      <td className="py-3 px-4 text-center">
                          <div className="flex items-center justify-center gap-2">
                            <button
                              onClick={() => handleEdit(code)}
                              disabled={isDeleting}
                              className="text-blue-500 hover:text-blue-700 transition-colors disabled:opacity-50"
                            >
                              <i className="bi bi-pencil-square text-lg"></i>
                            </button>
                            <button
                              onClick={() => handleDelete(code.id)}
                              disabled={isDeleting}
                              className="text-red-500 hover:text-red-700 transition-colors disabled:opacity-50"
                            >
                              {isDeleting ? (
                                <Loader className="w-4 h-4 animate-spin" />
                              ) : (
                                <Trash2 size={18} />
                              )}
                            </button>
                          </div>
                        </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-gray-500 text-center py-4">
                      Aucun code cadeau
                    </td>
                  </tr>
                )}
              </tbody>
            </table>)}
          </div>
          {filteredCodes.length > itemsPerPage && (
            <div className="flex items-center justify-between text-xs sm:text-sm md:text-base py-5 mt-4">
              <p className="font-medium text-gray-700">
                Affichage des données {startIndex + 1} à{" "}
                {Math.min(startIndex + itemsPerPage, filteredCodes.length)} sur{" "}
                {filteredCodes.length} entrées
              </p>

              <nav className="flex items-center space-x-1 md:space-x-2 font-medium">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 text-gray-700 disabled:opacity-50"
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                {visiblePages.map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`w-8 h-8 flex items-center justify-center rounded-full ${
                      currentPage === page
                        ? "bg-orange-primary text-white"
                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                  >
                    {page}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 text-gray-700 disabled:opacity-50"
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </nav>
            </div>
          )}
        </div>

        <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="gift-code-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography id="gift-code-modal-title" variant="h6" component="h2" mb={2}>
            {selectedCode ? "Modifier le code cadeau" : "Ajouter un code cadeau"}
          </Typography>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Code
              </label>
              <input
                type="text"
                value={formData.code}
                onChange={(e) =>
                  setFormData({ ...formData, code: e.target.value })
                }
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Réduction (%)
              </label>
              <input
                type="number"
                min="0"
                max="100"
                value={formData.discount}
                onChange={(e) =>
                  setFormData({ ...formData, discount: e.target.value })
                }
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date d'expiration
              </label>
              <input
                type="date"
                value={formData.expires_at}
                onChange={(e) =>
                  setFormData({ ...formData, expires_at: e.target.value })
                }
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
              />
            </div>
            <div className="flex justify-end gap-2 mt-6">
              <button
                type="button"
                onClick={handleModalClose}
                disabled={isSubmitting}
                className="px-4 py-2 border rounded-lg hover:bg-gray-50 transition-colors"
              >
                Annuler
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
              >
                {isSubmitting && <Loader className="w-4 h-4 animate-spin" />}
                {selectedCode ? (!isSubmitting && "Modifier") : (!isSubmitting && "Ajouter")}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      </div>
    </ParametresLayout>
  );
};

export default GiftCodes;