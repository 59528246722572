import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import { useLocation, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";

const FullCalendarCpt = ({ events }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const calendarRef = useRef(null);
  const datePickerRef = useRef(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleEventClick = (clickInfo) => {
    const reservationId = clickInfo.event.id;
    navigate(`/reservations/${reservationId}`, {
      state: { prevPath: location?.pathname },
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(date);
    }
    setIsDatePickerOpen(false);
  };

  const toggleDatePicker = (event) => {
    event.stopPropagation();
    setIsDatePickerOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsDatePickerOpen(!isDatePickerOpen);
    }
  };

  useEffect(() => {
    if (isDatePickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDatePickerOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        if (
          window.innerWidth < 768 &&
          calendarApi.view.type === "dayGridMonth"
        ) {
          calendarApi.changeView("listWeek");
        }
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderEventContent = (eventInfo) => {
    const viewType = eventInfo.view.type;

    return (
      <div className={`flex flex-row flex-wrap items-center justify-between ${viewType === "dayGridMonth" ? "" : viewType === "listWeek" ? "text-xs sm:text-sm lg:text-base" : "p-2 text-xs sm:text-sm lg:text-base"}`}>
        <div className={`${viewType === "dayGridMonth" ? "w-fit mx-auto font-bold text-center text-xs xl:text-sm" : viewType === "listWeek" ? "" : "font-bold"}`}>{eventInfo.timeText}</div>
        <div className={`uppercase ${viewType === "dayGridMonth" ? "w-fit mx-auto text-center text-xs xl:text-sm" : viewType === "listWeek" ? "" : ""}`}>{eventInfo.event.title}</div>
        {(viewType === "listWeek" || viewType === "dayGridDay") && (
          <div className={`${viewType === "dayGridMonth" ? "" : viewType === "listWeek" ? "" : ""}`}>
            {` ${eventInfo.event.extendedProps.description} `}
          </div>
        )}
      </div>
    );
  };

  const handleDateClick = (arg) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi.view.type === "dayGridMonth") {
        calendarApi.changeView("dayGridDay", arg.date);
      }
    }
  };

  const calendarStyles = `
  .fc-day,
  .fc-day-top,
  .fc-day-number,
  .fc-daygrid-day {
    cursor: grab !important;
  }
  
  .fc-day:active,
  .fc-day-top:active,
  .fc-day-number:active,
  .fc-daygrid-day:active {
    cursor: grabbing !important;
  }
`;


  return (
    <div className="bg-white pt-5 min-h-screen">
      <style>{calendarStyles}</style>
      <div className="p-2 mx-auto min-h-screen relative">
        {isDatePickerOpen && (
          <div
            ref={datePickerRef}
            className="absolute top-8 sm:top-10 right-32 sm:right-44 md:right-48 z-10 mt-2"
          >
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              wrapperClassName="date-picker-wrapper"
              inline
            />
          </div>
        )}

        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
          height="auto"
          weekends={true}
          locale={frLocale}
          events={events}
          eventContent={renderEventContent}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
            hour12: false,
          }}
          headerToolbar={{
            left: "dayGridMonth,listWeek,dayGridDay",
            center: "title",
            right: "datePicker prev,next today",
          }}
          buttonText={{
            listWeek: "Semaine",
          }}
          customButtons={{
            datePicker: {
              text: "Date",
              click: toggleDatePicker,
            },
          }}
          displayEventTime={true}
          displayEventEnd={true}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
        />
      </div>
    </div>
  );
};

export default FullCalendarCpt;