import React, { useState, useEffect, useCallback } from "react";
import { ParametresLayout } from "../../components";
import { Filter, Loader, Calendar } from "lucide-react";
import { apiClient } from "../../actions/api";
import FilterBtn from "../../components/globalcomponents/FilterBtn";
import SortByBtn from "../../components/globalcomponents/SortByBtn";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../context/authContext";
import {
  TrendingUp,
  TrendingDown,
  Users,
  CreditCard,
  BarChart3,
  Percent,
} from "lucide-react";
import { useSelector } from "react-redux";
const itemsPerPage = 6;

Date.prototype.getWeek = function () {
  const d = new Date(
    Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
  );
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

const formatDate = (date, periodType, customView) => {
  if (!date) return "";
  const parsedDate = new Date(date);
  if (!(parsedDate instanceof Date) || isNaN(parsedDate)) return "";

  if (periodType === "custom") {
    switch (customView) {
      case "daily":
        return parsedDate.toLocaleDateString("fr-FR", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      case "weekly":
        return `Semaine ${parsedDate.getWeek()} ${parsedDate.getFullYear()}`;
      case "monthly":
        return parsedDate.toLocaleDateString("fr-FR", {
          month: "long",
          year: "numeric",
        });
      default:
        return parsedDate.toLocaleDateString("fr-FR");
    }
  }

  // Original period type formatting
  switch (periodType) {
    case "week":
      return `Semaine ${parsedDate.getWeek()} ${parsedDate.getFullYear()}`;
    case "month":
      return parsedDate.toLocaleDateString("fr-FR", {
        month: "long",
        year: "numeric",
      });
    default:
      return parsedDate.toLocaleDateString("fr-FR");
  }
};

const CommissionDashboard = ({}) => {
  const { isSuperAdmin } = useAuth();
  const [periodType, setPeriodType] = useState("month");
  const [salonId, setSalonId] = useState("");
  const salon  = useSelector((state) => state?.data?.salon);
  const [salons, setSalons] = useState([]);
  const [selectedSalon, setSelectedSalon] = useState(null);
  const [commissionData, setCommissionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState("Date");
  const [filterOption, setFilterOption] = useState("Tout");
  const [isFilterSliderOpen, setIsFilterSliderOpen] = useState(false);
  const [summaryStats, setSummaryStats] = useState(null);
  const [customView, setCustomView] = useState("daily"); // Add this new state

  useEffect(() => {
    if (!isSuperAdmin) {
        setSalonId(salon?.id);
    }
  }, [])
  // Update the period type selector
  const PeriodSelector = () => {
    const periodTypeOptions = [
      { value: "week", label: "Semaine" },
      { value: "month", label: "Mois" },
    //   { value: "custom", label: "Personnalisé" },
    ];

    return (
      <div className="flex flex-col md:flex-row gap-2">
        <div className="flex items-center gap-2">
          <label>Type de période:</label>
          <select
            value={periodType}
            onChange={(e) => setPeriodType(e.target.value)}
            className="border rounded p-2"
          >
            {periodTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Show additional options when "Personnalisé" is selected */}
        {periodType === "custom" && (
          <div className="flex items-center gap-2">
            <label>Vue:</label>
            <select
              value={customView}
              onChange={(e) => setCustomView(e.target.value)}
              className="border rounded p-2"
            >
              <option value="daily">Journalière</option>
              <option value="weekly">Hebdomadaire</option>
              <option value="monthly">Mensuelle</option>
            </select>
          </div>
        )}
      </div>
    );
  };

  const fetchSalons = async () => {
    try {
      const response = await apiClient().get("/salons");
      setSalons(response?.data || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des salons:", error);
      toast.error("Impossible de récupérer les salons");
    }
  };

  useEffect(() => {
    if (isSuperAdmin) {
      fetchSalons();
    }
  }, [isSuperAdmin]);

  const fetchCommissionData = async () => {
    setIsLoading(true);
    try {
      let params = {
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        salon_id: isSuperAdmin ? selectedSalon : salonId,
        period_type: periodType === "custom" ? customView : periodType,
      };

      const response = await apiClient().get("/commission/stats", { params });

      // Transform data based on period type and custom view
      let transformedData = response?.data?.data || [];

      if (periodType === "custom") {
        switch (customView) {
          case "daily":
            // Data is already daily, no transformation needed
            break;
          case "weekly":
            transformedData = groupDataByWeek(transformedData);
            break;
          case "monthly":
            transformedData = groupDataByMonth(transformedData);
            break;
        }
      }

      setCommissionData(transformedData);

      // Fetch summary as before
      const summaryResponse = await apiClient().get("/commission/summary", {
        params,
      });
      setSummaryStats(summaryResponse?.data?.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
      toast.error("Impossible de récupérer les données des commissions");
    } finally {
      setIsLoading(false);
    }
  };

  // Helper functions for data grouping
  const groupDataByWeek = (data) => {
    const weeklyData = {};

    data.forEach((item) => {
      const date = new Date(item.created_at);
      const weekKey = `${date.getFullYear()}-W${date.getWeek()}`;

      if (!weeklyData[weekKey]) {
        weeklyData[weekKey] = {
          created_at: date.toISOString(),
          gross_revenue: 0,
          total_bookings: 0,
          completed_bookings: 0,
          commission_amount: 0,
          net_revenue: 0,
        };
      }

      // Sum up the values
      weeklyData[weekKey].gross_revenue += item.gross_revenue;
      weeklyData[weekKey].total_bookings += item.total_bookings;
      weeklyData[weekKey].completed_bookings += item.completed_bookings;
      weeklyData[weekKey].commission_amount += item.commission_amount;
      weeklyData[weekKey].net_revenue += item.net_revenue;
    });

    return Object.values(weeklyData);
  };

  const groupDataByMonth = (data) => {
    const monthlyData = {};

    data.forEach((item) => {
      const date = new Date(item.created_at);
      const monthKey = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;

      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = {
          created_at: date.toISOString(),
          gross_revenue: 0,
          total_bookings: 0,
          completed_bookings: 0,
          commission_amount: 0,
          net_revenue: 0,
        };
      }

      // Sum up the values
      monthlyData[monthKey].gross_revenue += item.gross_revenue;
      monthlyData[monthKey].total_bookings += item.total_bookings;
      monthlyData[monthKey].completed_bookings += item.completed_bookings;
      monthlyData[monthKey].commission_amount += item.commission_amount;
      monthlyData[monthKey].net_revenue += item.net_revenue;
    });

    return Object.values(monthlyData);
  };

  useEffect(() => {
    fetchCommissionData();
  }, [dateRange, selectedSalon, periodType]);

  const sortData = useCallback((dataToSort, option) => {
    return [...dataToSort].sort((a, b) => {
      if (option === "Date") {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (option === "Chiffre d'affaires") {
        return b.gross_revenue - a.gross_revenue;
      } else if (option === "Commission") {
        return b.commission_amount - a.commission_amount;
      }
      return 0;
    });
  }, []);

  const updateDataList = useCallback(() => {
    let result = commissionData;
    result = sortData(result, sortOption);
    setFilteredData(result);
  }, [commissionData, sortOption, sortData]);

  useEffect(() => {
    updateDataList();
  }, [updateDataList]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const SalonSelector = () => {
    if (!isSuperAdmin) return null;

    return (
      <div className="relative w-48">
        <select
          value={selectedSalon || ""}
          onChange={(e) => setSelectedSalon(e.target.value)}
          className="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
        >
          <option value="" disabled>
            Sélectionner un salon
          </option>
          {salons?.map((salon) => (
            <option key={salon?.id} value={salon?.id}>
              {salon.name}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
    );
  };

  const RevenueCharts = () => {
    if (!commissionData.length) return null;

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* Revenue Bar Chart */}
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">Aperçu des revenus</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={commissionData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="created_at"
                tickFormatter={(date) => formatDate(date, periodType)}
              />
              <YAxis />
              <Tooltip
                formatter={(value) => `${value.toLocaleString()} Dhs`}
                labelFormatter={(label) => formatDate(label, periodType)}
              />
              <Legend />
              <Bar
                dataKey="gross_revenue"
                name="Chiffre d'affaires"
                fill="#8884d8"
              />
              <Bar
                dataKey="commission_amount"
                name="Commission"
                fill="#82ca9d"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Bookings Line Chart */}
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">
            Tendances des réservations
          </h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={commissionData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="created_at"
                tickFormatter={(date) => formatDate(date, periodType)}
              />
              <YAxis />
              <Tooltip
                formatter={(value) => value}
                labelFormatter={(label) => formatDate(label, periodType)}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="total_bookings"
                name="Réservations totales"
                stroke="#8884d8"
              />
              <Line
                type="monotone"
                dataKey="completed_bookings"
                name="Réservations complétées"
                stroke="#82ca9d"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const StatCard = ({ title, icon: Icon, stats }) => (
    <div className="bg-stone-400/10 p-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
      <div className="flex items-center gap-3 mb-4">
        <div className="p-3 rounded-full bg-orange-50">
          <Icon className="w-6 h-6 text-orange-500" />
        </div>
        <h4 className="text-gray-700 font-semibold">{title}</h4>
      </div>
      <div className="space-y-3">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-2 hover:bg-gray-50 rounded-md transition-colors duration-200"
          >
            <span className="text-gray-600">{stat.label}</span>
            <div className="flex items-center gap-2">
              {stat.trend && (
                <div
                  className={`${
                    stat.trend === "up" ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {stat.trend === "up" ? (
                    <TrendingUp className="w-4 h-4" />
                  ) : (
                    <TrendingDown className="w-4 h-4" />
                  )}
                </div>
              )}
              <span
                className={`font-medium ${
                  stat.highlight ? "text-orange-600" : "text-gray-800"
                }`}
              >
                {stat.value}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const EnhancedSummaryStats = ({ summaryStats }) => {
    if (!summaryStats) return null;

    const bookingStats = [
      {
        label: "Total",
        value: summaryStats.total_bookings,
        highlight: true,
      },
      {
        label: "Complétées",
        value: summaryStats.completed_bookings,
        trend: "up",
      },
      {
        label: "Annulées",
        value: summaryStats.cancelled_bookings,
        trend: "down",
      },
    ];

    const paymentStats = [
      {
        label: "Espèces",
        value: `${summaryStats.cash_revenue?.toLocaleString()} Dhs`,
        highlight: true,
      },
      {
        label: "Carte",
        value: `${summaryStats.card_revenue?.toLocaleString()} Dhs`,
        highlight: true,
      },
      {
        label: "Moyenne",
        value: `${summaryStats.average_booking_value?.toLocaleString()} Dhs`,
      },
    ];

    const rateStats = [
      {
        label: "Complétion",
        value: `${summaryStats.completion_rate}%`,
        trend: "up",
      },
      {
        label: "Annulation",
        value: `${summaryStats.cancellation_rate}%`,
        trend: "down",
      },
    ];

    return (
      <div className="bg-white p-6 rounded-xl shadow-md">
        <h3 className="text-xl font-semibold mb-6 text-gray-800 flex items-center gap-2">
          <BarChart3 className="w-6 h-6 text-orange-500" />
          Statistiques détaillées
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <StatCard title="Réservations" icon={Users} stats={bookingStats} />
          <StatCard title="Paiements" icon={CreditCard} stats={paymentStats} />
          <StatCard title="Taux" icon={Percent} stats={rateStats} />
        </div>
      </div>
    );
  };

  return (
    <ParametresLayout>
      <ToastContainer />
      <div className="p-3 min-h-screen space-y-5">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex items-center space-x-2">
              <input
                type="date"
                value={dateRange.startDate}
                onChange={(e) =>
                  setDateRange((prev) => ({
                    ...prev,
                    startDate: e.target.value,
                  }))
                }
                className="border rounded p-2"
              />
              <span>au</span>
              <input
                type="date"
                value={dateRange.endDate}
                onChange={(e) =>
                  setDateRange((prev) => ({ ...prev, endDate: e.target.value }))
                }
                className="border rounded p-2"
              />
            </div>
            <SalonSelector />
            <PeriodSelector />
          </div>
        </div>
        <RevenueCharts />
        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-gray-500 text-sm mb-2">
              Chiffre d'affaires total
            </h3>
            <p className="text-2xl font-bold">
              {summaryStats?.gross_revenue
                ? summaryStats?.gross_revenue?.toLocaleString()
                : 0}{" "}
              Dhs
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-gray-500 text-sm mb-2">Commission (15%)</h3>
            <p className="text-2xl font-bold">
              {summaryStats?.commission_amount?.toLocaleString()} Dhs
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-gray-500 text-sm mb-2">Revenu net</h3>
            <p className="text-2xl font-bold">
              {summaryStats?.net_revenue?.toLocaleString()} Dhs
            </p>
          </div>
        </div>

        <div className="bg-white text-sm shadow-md rounded-lg">
          <div className="overflow-x-auto">
            {isLoading ? (
              <div className="flex items-center justify-center p-8">
                <Loader className="w-6 h-6 animate-spin text-orange-500" />
                <span className="ml-2">Chargement des données...</span>
              </div>
            ) : (
              <table className="w-full table-auto border-collapse">
                <thead>
                  <tr className="bg-gray-100 text-gray-800 text-left border-b text-xs sm:text-sm md:text-sm lg:text-md font-semibold">
                    <th className="py-3 px-4">Date</th>
                    <th className="py-3 px-4">Réservations</th>
                    <th className="py-3 px-4">Chiffre d'affaires</th>
                    <th className="py-3 px-4">Commission (15%)</th>
                    <th className="py-3 px-4">Net</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.length ? (
                    currentData.map((item, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 text-gray-700 border-b text-xs sm:text-sm md:text-sm lg:text-md"
                      >
                        <td className="py-3 px-4">
                          {formatDate(item.created_at, periodType)}
                        </td>
                        <td className="py-3 px-4">{item.total_bookings}</td>
                        <td className="py-3 px-4">
                          {item.gross_revenue.toLocaleString()} Dhs
                        </td>
                        <td className="py-3 px-4">
                          {item.commission_amount.toLocaleString()} Dhs
                        </td>
                        <td className="py-3 px-4">
                          {item.net_revenue.toLocaleString()} Dhs
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className="text-gray-500 text-center py-4"
                      >
                        Aucune donnée disponible
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>

          {filteredData?.length > itemsPerPage && (
            <div className="flex items-center justify-between p-4 border-t">
              <p className="text-sm text-gray-700">
                Affichage {startIndex + 1} à{" "}
                {Math.min(startIndex + itemsPerPage, filteredData.length)} sur{" "}
                {filteredData.length} entrées
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(1, prev - 1))
                  }
                  disabled={currentPage === 1}
                  className="px-3 py-1 rounded-md bg-gray-100 disabled:opacity-50"
                >
                  Précédent
                </button>
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                  }
                  disabled={currentPage === totalPages}
                  className="px-3 py-1 rounded-md bg-gray-100 disabled:opacity-50"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}
        </div>

        {summaryStats && <EnhancedSummaryStats summaryStats={summaryStats} />}
      </div>
    </ParametresLayout>
  );
};

export default CommissionDashboard;
