import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import Layout from '../layout';
import { Cog6ToothIcon, UserGroupIcon, HomeIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { GiftIcon, Percent } from 'lucide-react';

const ParametresLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { isSuperAdmin } = useAuth();

  const isActive = (path) => {
    const currentPath = location.pathname;
    if (currentPath === path) return true;
    if (path === "/parametres") {
      return ["/parametres/avis", "/parametres/plaintes", "/parametres/emails", "/parametres/services", "/parametres/remarques"].includes(currentPath);
    }
    if (path === "/parametres/salons") return /^\/parametres\/salons\/\d+$/.test(currentPath);
    if (path === "/parametres/employees") return /^\/parametres\/employees\/\d+$/.test(currentPath);
    return false;
  };

  const tabs = !isSuperAdmin
    ? [
        {
          title: "Paramètres Généraux",
          path: "/parametres/avis",
          icon: <Cog6ToothIcon className="w-5 h-5" />,
          subTabs: [
            { title: "Avis & Commentaires", path: "/parametres/avis" },
            { title: "Remarques", path: "/parametres/remarques" },
            { title: "Services", path: "/parametres/services" },
          ],
        },
        { title: "Employées", path: "/parametres/employees", icon: <UserGroupIcon className="w-5 h-5" /> },
        { title: "Salon", path: "/parametres/salon", icon: <HomeIcon className="w-5 h-5" /> },
        { title: "Comission", path: "/parametres/comission", icon: <Percent className="w-5 h-5" /> },
      ]
    : [
        {
          title: "Paramètres Généraux",
          path: "/parametres/avis",
          icon: <Cog6ToothIcon className="w-5 h-5" />,
          subTabs: [
            { title: "Avis & Commentaires", path: "/parametres/avis" },
            { title: "Plaintes", path: "/parametres/plaintes" },
            { title: "Emails", path: "/parametres/emails" },
            { title: "Services", path: "/parametres/services" },
          ],
        },
        { title: "Salons", path: "/parametres/salons", icon: <HomeIcon className="w-5 h-5" /> },
        { title: "Code promo", path: "/parametres/promo-codes", icon: <GiftIcon className="w-5 h-5" /> },
        { title: "Comission", path: "/parametres/comission", icon: <Percent className="w-5 h-5" /> },
      ];

  return (
    <Layout>
      <div className="p-4">
        <nav className="mb-6">
          <div className="flex flex-wrap justify-center gap-2">
            {tabs.map((tab, index) => (
              <div key={index} className="relative">
                <button
                  onClick={() => {
                    navigate(tab.path);
                    setActiveDropdown(activeDropdown === index ? null : index);
                  }}
                  className={`flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200 ${
                    isActive(tab.path)
                      ? 'bg-orange-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {tab.icon}
                  <span className="ml-2">{tab.title}</span>
                  {tab.subTabs && (
                    <ChevronDownIcon 
                      className={`w-4 h-4 ml-1 transition-transform duration-200 ${
                        activeDropdown === index ? 'transform rotate-180' : ''
                      }`} 
                    />
                  )}
                </button>

                {tab.subTabs && activeDropdown === index && (
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <div className="py-1">
                      {tab.subTabs.map((subTab, subIndex) => (
                        <button
                          key={subIndex}
                          onClick={() => {
                            navigate(subTab.path);
                            setActiveDropdown(null);
                          }}
                          className={`block w-full text-left px-4 py-2 text-sm ${
                            isActive(subTab.path)
                              ? 'bg-orange-100 text-orange-700'
                              : 'text-gray-700 hover:bg-gray-100'
                          }`}
                        >
                          {subTab.title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </nav>

        <main>{children}</main>
      </div>
    </Layout>
  );
};

export default ParametresLayout;