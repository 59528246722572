import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { ProfileImg } from "../../assets/images";
import getBookingStatus from "../../helpers/getBookingStatus";
import FormateDateRange from '../../helpers/FormateDateRange';
import { BASE_IMAGE_URL } from "../../constants/actions";
import { ChevronDown, ChevronLeft, ChevronRight, Filter, SortAsc } from "lucide-react";
import SortBy from "../globalcomponents/SortByBtn";
import FilterBtn from "../globalcomponents/FilterBtn";
import { useAuth } from "../../context/authContext";

const ReservationTable = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const [selectedSalon, setSelectedSalon] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFilterSliderOpen, setIsFilterSliderOpen] = useState(false);
  const dropdownRef1 = useRef(null);
  const { isSuperAdmin } = useAuth();

  const handleClickOutside1 = (event) => {
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside1);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside1);
    };
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  // Filter reservations based on selected salon
  const filteredReservations = selectedSalon
    ? props?.reservations?.filter(reservation => reservation?.salon?.name === selectedSalon)
    : props?.reservations || [];

  const itemsPerPage = props?.itemsPerPage || 10;
  const totalPages = Math.ceil(filteredReservations.length / itemsPerPage);
  const maxVisibleButtons = 3;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [selectedSalon]);

  useEffect(() => {
    updateVisiblePages(currentPage);
  }, [currentPage, totalPages]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredReservations.length);

  const uniqueSalons = Array.from(new Set(props?.reservations?.map(reservation => reservation?.salon?.name) || []));

  const visibleRows = filteredReservations.slice(startIndex, endIndex);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleSalonSelect = (salon) => {
    setSelectedSalon(salon);
    setCurrentPage(1);
    setIsDropdownOpen(false);
  };

  const toggleFilterSlider = () => {
    setIsFilterSliderOpen(prev => !prev);
  };

  const renderPageButtons = () => (
    visiblePages.map((page) => (
      <button
        key={page}
        onClick={() => handlePageChange(page)}
        className={`mx-1 w-8 h-8 ${
          currentPage === page
            ? "bg-orange-500 text-white"
            : "text-gray-700 bg-gray-200"
        } rounded-full flex items-center justify-center`}
      >
        {page}
      </button>
    ))
  );

  const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };


  return (
    <div className="rounded-lg shadow-md">
      <div className="">
        <div className="p-4 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="w-full md:w-1/3">
            <input
              type="text"
              placeholder="Nom, Prénom"
              value={props?.searchInput}
              onChange={(e) => props?.setSearchInput(e.target.value)}
              className="w-full py-2 px-4 border rounded-full outline-none focus:ring-2 focus:ring-orange-500 text-md"
            />
          </div>
          <div className="md:hidden">
            <button
              onClick={toggleFilterSlider}
              className="w-full bg-orange-500 text-white py-2 px-4 rounded-full flex items-center justify-center"
            >
              <Filter size={18} className="mr-2" /> Filtres
            </button>
          </div>
          <div className={`flex flex-col md:flex-row gap-2 ${isFilterSliderOpen ? 'block' : 'hidden md:flex'}`}>
            {isSuperAdmin && <div className="relative">
              <button 
                className="w-full md:w-auto bg-white border rounded-full py-2 px-4 flex items-center justify-between"
                onClick={handleDropdownToggle}
              >
                <span>{selectedSalon || 'Tous les salons'}</span>
                <ChevronDown size={18} />
              </button>
              {isDropdownOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-lg" ref={dropdownRef1}>
                  <div
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSalonSelect('')}
                  >
                    Tous les salons
                  </div>
                  {uniqueSalons.map((salon, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleSalonSelect(salon)}
                    >
                      {salon}
                    </div>
                  ))}
                </div>
              )}
            </div>}
            <div className="relative">
              <SortBy
              sortOptions={props?.sortOptions}
              sortOption={props?.sortOption}
              setSortOption={props?.setSortOption}
            />
            </div>
           
            <div className="relative">
            <FilterBtn
              filterOptions={props?.filterOptions}
              filterOption={props?.filterOption}
              setFilterOption={props?.setFilterOption}
            />
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto pt-2 px-4">
        <table className="w-full text-sm">
          <thead>
            <tr className="bg-stone-500">
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">Client</th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">Salon</th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">Date</th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">Prix</th>
              <th className="py-3 px-4 text-white text-center whitespace-nowrap">Statut</th>
              <th className="py-3 px-4 text-white text-center whitespace-nowrap">Action</th>
            </tr>
          </thead>
          <tbody>
            {visibleRows.length > 0 ? visibleRows.map((reservation) => {
              const { type, style } = reservation?.availability?.date ? getBookingStatus(
                reservation?.availability?.date,
                reservation?.availability?.start_time,
                reservation?.availability?.end_time,
                reservation?.status
              ) : {};
              return (
                <tr key={reservation.id} className="border-b hover:bg-gray-50">
                  <td className="py-3 px-4 whitespace-nowrap">
                    <div className="flex items-center space-x-2 max-w-[200px]">
                      <img
                        src={reservation?.user?.avatar ? BASE_IMAGE_URL + reservation?.user?.avatar : ProfileImg}
                        alt="avatar"
                        className="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex-shrink-0"
                      />
                      <div className="flex-grow min-w-0">
                        <p className="font-medium truncate">
                          {reservation?.user?.first_name ? `${reservation?.user?.first_name} ${reservation?.user?.last_name}` : (reservation?.guest?.name ? reservation?.guest?.name : 'N/A')}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 sm:table-cell">{reservation?.salon?.name}</td>
                  <td className="py-3 px-2 whitespace-nowrap">
                    <div className="flex flex-col">
                      <span className="font-medium">
                        {reservation?.availability?.date && new Date(reservation?.availability?.date).toLocaleDateString()}
                      </span>
                      <span className="text-sm text-gray-500">
                        {formatTime(reservation?.availability?.start_time)} - {formatTime(reservation?.availability?.end_time)}
                      </span>
                    </div>
                  </td>
                  <td className="py-3 px-2 whitespace-nowrap sm:table-cell">{reservation?.payment?.amount} Dhs</td>
                  <td className="py-3 px-2 flex justify-center mt-2 whitespace-nowrap">
                    <div className={`px-2 py-1 max-w-fit text-xs sm:text-sm rounded-full text-white text-center ${style}`}>
                      {type}
                    </div>
                  </td>
                  <td className="py-3 px-2 text-center">
                    <button
                      onClick={() => navigate(`/reservations/${reservation?.id}`, { state: { prevPath: location?.pathname } })}
                      className="bg-orange-500 text-white px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm rounded-full hover:bg-orange-600 transition duration-300"
                    >
                      Détails
                    </button>
                  </td>
                </tr>
              );
            }) : (
              <tr>
                <td colSpan="6" className="text-center py-4">Aucune réservation trouvée.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filteredReservations.length > itemsPerPage && (
        <div className="flex justify-center items-center p-4 pb-12">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="mr-2 p-2 rounded-full bg-gray-200 disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>
          {renderPageButtons()}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="ml-2 p-2 rounded-full bg-gray-200 disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ReservationTable;